<template>
  <div>
    <main-app-bar>
      <template v-slot:title>
        <v-skeleton-loader v-if="isLoading" type="heading" width="500" />
        <span v-else>
          <span v-if="!$attrs.id"> Promo Details </span>
          <span v-else> {{ selectedPromo.title }} </span>
        </span>
      </template>

      <template v-if="hasCurrentPromoId" v-slot:top-actions>
        <v-btn
          v-if="!isLoading"
          @click="deletePromo"
          :loading="isDeleting"
          color="error"
          text
          dark
        >
          <v-icon class="mr-3"> {{ icons.delete }} </v-icon>
          Delete Promo
        </v-btn>

        <v-skeleton-loader v-else type="heading" width="200" />
      </template>
    </main-app-bar>

    <div class="mt-5 px-lg-12 px-md-6 px-5 pt-4 pb-8">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>

    <confirm-dialog ref="confirmDelete" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import MainAppBar from '@/layouts/shared/MainAppBar'

import { mapActions, mapGetters } from 'vuex'
import { mdiTrashCanOutline } from '@mdi/js'
import { isEmpty } from 'lodash'

export default {
  name: 'PromoInformations',

  components: {
    ConfirmDialog,
    MainAppBar,
  },

  data() {
    return {
      icons: {
        delete: mdiTrashCanOutline,
      },
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'promos/isLoading',
      isDeleting: 'promos/isDeleting',
      selectedPromo: 'promos/selectedPromo',
    }),

    hasCurrentPromoId() {
      return !isEmpty(this.$attrs.id)
    },
  },

  methods: {
    ...mapActions({
      deleteSelectedPromo: 'promos/deletePromo',
    }),

    async deletePromo() {
      const confirm = await this.$refs.confirmDelete.open(
        'Delete Promo',
        'Are you sure you want to remove this promo?'
      )

      if (confirm) {
        await this.deleteSelectedPromo(parseInt(this.$route.params.id))
        this.$router.replace({ name: 'promos' })
      }
    },
  },
}
</script>
